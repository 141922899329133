import React, { Suspense, Lazy, useEffect, useState } from "react";
import Navbar from "./Navigations/Navbar/Navbar";
import Footer from "./Navigations/Footer";
import Loader from "./components/Loader";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import axios from "axios";
import Microsite from "./pages/Microsite/Microsite";

const Career = React.lazy(() => import("./pages/Career/Career"));
const Career_Details = React.lazy(() =>
  import("./pages/Career/Career_Details/Career_Details")
);
const AISS = React.lazy(() => import("./pages/Events/AISS"));
const DsciEvents = React.lazy(() => import("./pages/Events/DsciEvents"));
const Member_Directory = React.lazy(() =>
  import("./pages/Corporate Members/Member_Directory")
);
const Whatnew = React.lazy(() => import("./pages/Whatnew/Whatnew"));
const WhatnewDetail = React.lazy(() => import("./pages/Whatnew/WhatnewDetail"));
const Media_Coverage = React.lazy(() =>
  import("./pages/Media-Centre/Media_Coverage")
);

// Forms
const JoinChapter = React.lazy(() => import("./pages/Forms.js/JoinChapter"));
const ChangeChapter = React.lazy(() =>
  import("./pages/Forms.js/ChangeChapter")
);
const DCPPForm = React.lazy(() => import("./pages/Forms.js/DCPPForm"));
const DCPLAForm = React.lazy(() => import("./pages/Forms.js/DCPLAForm"));
const ReportForm = React.lazy(() => import("./pages/Forms.js/ReportForm"));
const Response = React.lazy(() => import("./pages/Others/Response"));
const ThankYou = React.lazy(() => import("./pages/Forms.js/ThankYou"));
const CyberShikshaForm = React.lazy(() =>
  import("./pages/Forms.js/CyberShikshaForm")
);
const PrivacyModule = React.lazy(() =>
  import("./pages/Forms.js/PrivacyModule")
);

const MemberDetail = React.lazy(() =>
  import("./pages/Corporate Members/MemberDetail")
);
const Resource_Centre = React.lazy(() =>
  import("./pages/Resource_Centre/Resource_Centre")
);
const ReportDetail = React.lazy(() =>
  import("./pages/Resource_Centre/ReportDetail")
);
const Advisory = React.lazy(() => import("./pages/Advisory/Advisory"));
const SearchResults = React.lazy(() =>
  import("./pages/General Pages/SearchResults")
);

const Home = React.lazy(() => import("./pages/HomePage/Home"));
const CyberSecurityAwarenessMonth = React.lazy(() =>
  import("./pages/Page Template/CyberSecurityAwarenessMonth")
);
const EventDetail = React.lazy(() => import("./pages/Events/EventDetail"));
const AdvisoryDetail = React.lazy(() =>
  import("./pages/Advisory/AdvisoryDetail")
);
const NotFound = React.lazy(() => import("./pages/General Pages/NotFound"));

function App() {
  const [bData, setData] = useState({});
  const [loader, setLoader] = useState(false);
  const [maintainance, setMaintainance] = useState(false);
  let alias = window.location.href.replace("https://www.dsci.in/content", "");
  let hash = alias.split("#")[0];
  if (undefined !== hash && hash.length > 1) {
    alias = hash;
  }

  let ques = alias.split("?")[0];
  if (undefined !== ques && ques.length > 1) {
    alias = ques;
  }
  //  /api/v1/maintenance_mode
  const API = `${process.env.REACT_APP_API_URL}/api/v1/maintenance_mode`;
  const API_KEY = "f5a00727369e5ba1e0d2f3b897b0d8c3";
  const fetchData = async () => {
    setLoader(true);
    var config = {
      method: "get",
      url: API,
      headers: {
        "api-key": API_KEY,
        // "Content-Type": "application/json",
      },
    };
    await axios(config)
      .then((response) => {
        if (response?.data) {
          setLoader(false);
          if (response?.data?.maintenance_mode === 1) setMaintainance(true);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    fetchData();
  }, []);

  //  /api/v1/content
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    const currentPath = window.location.pathname;

  // Extract the last segment of the URL
    const lastSegment = currentPath.substring(currentPath.lastIndexOf('/') + 1);
    
    const API1 = `${process.env.REACT_APP_API_URL}/api/v1/content/${lastSegment}`;
    const API_KEY1 = process.env.REACT_APP_API_KEY;
    const fetchApiData = async (url) => {
      try {
        const res = await fetch(url, {
        headers: {
            "api-key": API_KEY1,
        },
        });
        const data = await res.json();
        setData(data);
      } catch (error) {
        console.log(error);
      }
    };
    fetchApiData(API1);
  }, []);

  return (
    <>
      
      {!maintainance ? (
        <BrowserRouter>
          {/* <Header /> */}
          {bData?.type?.target_id !== 'microsite' ? <Navbar /> : ''}
          {/* <Navbar /> */}
          <Suspense fallback={<Loader />}>
            <Routes>
              <Route
                exact
                path="/"
                element={
                  <Suspense fallback={<Loader />}>
                    <Home />
                  </Suspense>
                }
              />
              {/* <Route
                // path="microsite/content/:id"
                path={`/content${alias}`}
                element={
                  <Suspense fallback={<Loader />}>
                    <Microsite value={bData} endpoint={`${alias}`} />
                  </Suspense>
                }
              /> */}
              <Route
                path={`/content${alias}`}
                element={
                  <Suspense fallback={<Loader />}>
                    <CyberSecurityAwarenessMonth endpoint={`${alias}`} />
                  </Suspense>
                }
              />
              <Route
                path={`/content${alias}:id`}
                element={
                  <Suspense fallback={<Loader />}>
                    <CyberSecurityAwarenessMonth endpoint={`${alias}`} />
                  </Suspense>
                }
              />

              <Route
                exact
                path="/cyber-shikshaa-registration"
                element={
                  <Suspense fallback={<Loader />}>
                    <CyberShikshaForm />
                  </Suspense>
                }
              />
              <Route
                exact
                path="/privacy-module-registration"
                element={
                  <Suspense fallback={<Loader />}>
                    <PrivacyModule />
                  </Suspense>
                }
              />
              <Route
                path="/search"
                element={
                  <Suspense fallback={<Loader />}>
                    <SearchResults />
                  </Suspense>
                }
              />
              <Route
                path="/search:id"
                element={
                  <Suspense fallback={<Loader />}>
                    <SearchResults />
                  </Suspense>
                }
              />

              <Route
                exact
                path="/position-openings"
                element={
                  <Suspense fallback={<Loader />}>
                    <Career />
                  </Suspense>
                }
              />

              <Route
                exact
                path="resource/content/:id"
                element={
                  <Suspense fallback={<Loader />}>
                    <ReportDetail />
                  </Suspense>
                }
              />
              <Route
                exact
                path="/advisory"
                element={
                  <Suspense fallback={<Loader />}>
                    <Advisory />
                  </Suspense>
                }
              />
              <Route
                path="/advisory/:id"
                element={
                  <Suspense fallback={<Loader />}>
                    <Advisory />
                  </Suspense>
                }
              />
              <Route
                path="advisory/content/:id"
                element={
                  <Suspense fallback={<Loader />}>
                    <AdvisoryDetail />
                  </Suspense>
                }
              />
              <Route
                exact
                path="/knowledge-center"
                element={
                  <Suspense fallback={<Loader />}>
                    <Resource_Centre />
                  </Suspense>
                }
              />
              <Route
                path="/knowledge-center/:id"
                element={
                  <Suspense fallback={<Loader />}>
                    <Resource_Centre />
                  </Suspense>
                }
              />

              {/* <Route
              exact
              path="/corporate-member"
              element={
                <Suspense fallback={<Loader />}>
                  <Member_Directory />
                </Suspense>
              }
            /> */}
              <Route
                exact
                path="/corporate-member/member-directory"
                element={
                  <Suspense fallback={<Loader />}>
                    <Member_Directory />
                  </Suspense>
                }
              />
              {/* <Route
              path="corporate-member/:id"
              element={
                <Suspense fallback={<Loader />}>
                  <MemberDetail />
                </Suspense>
              }
            /> */}
              <Route
                path="position-openings/content/:id"
                element={
                  <Suspense fallback={<Loader />}>
                    <Career_Details />
                  </Suspense>
                }
              />

              <Route
                exact
                path="/latest-at-dsci"
                element={
                  <Suspense fallback={<Loader />}>
                    <Whatnew />
                  </Suspense>
                }
              />
              <Route
                exact
                path="latest-at-dsci/content/:id"
                element={
                  <Suspense fallback={<Loader />}>
                    <WhatnewDetail />
                  </Suspense>
                }
              />

              <Route
                exact
                path="/media-centre"
                element={
                  <Suspense fallback={<Loader />}>
                    <Media_Coverage />
                  </Suspense>
                }
              />
              <Route
                exact
                path="/media-centre/:id"
                element={
                  <Suspense fallback={<Loader />}>
                    <Media_Coverage />
                  </Suspense>
                }
              />

              <Route
                exact
                path="/chapter-registration"
                element={
                  <Suspense fallback={<Loader />}>
                    <JoinChapter />
                  </Suspense>
                }
              />
              <Route
                exact
                path="/chapter-change"
                element={
                  <Suspense fallback={<Loader />}>
                    <ChangeChapter />
                  </Suspense>
                }
              />
              <Route
                exact
                path="/dcpp-registration"
                element={
                  <Suspense fallback={<Loader />}>
                    <DCPPForm />
                  </Suspense>
                }
              />
              <Route
                exact
                path="/dcpla-registration"
                element={
                  <Suspense fallback={<Loader />}>
                    <DCPLAForm />
                  </Suspense>
                }
              />
              <Route
                path="/response"
                element={
                  <Suspense fallback={<Loader />}>
                    <Response />
                  </Suspense>
                }
              />
              <Route
                path="/thankyou"
                element={
                  <Suspense fallback={<Loader />}>
                    <ThankYou />
                  </Suspense>
                }
              />
              <Route
                exact
                path={`/generalpayment/:nid`}
                element={
                  <Suspense fallback={<Loader />}>
                    <ReportForm />
                  </Suspense>
                }
              />

              <Route
                exact
                path="/events"
                element={
                  <Suspense fallback={<Loader />}>
                    <DsciEvents />
                  </Suspense>
                }
              />
              <Route
                path="events/:pathname"
                element={
                  <Suspense fallback={<Loader />}>
                    <AISS />
                  </Suspense>
                }
              />
              <Route
                path="events/content/:id"
                element={
                  <Suspense fallback={<Loader />}>
                    <EventDetail />
                  </Suspense>
                }
              />

              <Route
                exact
                path="/knowledge-center/annual-reports"
                element={
                  <Suspense fallback={<Loader />}>
                    <Resource_Centre />
                  </Suspense>
                }
              />
              <Route
                exact
                path="/knowledge-center/event-reports"
                element={
                  <Suspense fallback={<Loader />}>
                    <Resource_Centre />
                  </Suspense>
                }
              />
              <Route
                exact
                path="/knowledge-center/newsletters"
                element={
                  <Suspense fallback={<Loader />}>
                    <Resource_Centre />
                  </Suspense>
                }
              />
              <Route
                exact
                path="/knowledge-center/pov"
                element={
                  <Suspense fallback={<Loader />}>
                    <Resource_Centre />
                  </Suspense>
                }
              />
              <Route
                exact
                path="/knowledge-center/study-reports"
                element={
                  <Suspense fallback={<Loader />}>
                    <Resource_Centre />
                  </Suspense>
                }
              />
              <Route
                exact
                path="/knowledge-center/whitepapers"
                element={
                  <Suspense fallback={<Loader />}>
                    <Resource_Centre />
                  </Suspense>
                }
              />

              <Route
                path="*"
                element={
                  <Suspense fallback={<Loader />}>
                    <NotFound />
                  </Suspense>
                }
              />
            </Routes>
          </Suspense>
          {bData?.type?.target_id !== 'microsite' ? <Footer /> : ''}
          {/* <Footer /> */}
        </BrowserRouter>
      ) : (
        <div className="text-center mt-5 p-5">
          <h1>Site is under maintainance, we will get back shortly</h1>
        </div>
      )}
    </>
  );
}

export default App;
