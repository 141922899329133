import React, { useState, useEffect } from "react";
import Loader from "../HomePage/Loader";
import { Helmet, HelmetProvider } from "react-helmet-async";

function Microsite({ value, endpoint }) {
  const [bData, setData] = useState({});
  const [loader, setloader] = useState("");

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });

    const API = `${process.env.REACT_APP_API_URL}/api/v1/content${endpoint}`;

    const API_KEY = process.env.REACT_APP_API_KEY;
    const fetchApiData = async (url) => {
        setloader(true);
        try {
            const res = await fetch(url, {
            headers: {
                "api-key": API_KEY,
            },
            });
            const data = await res.json();
            setData(data);
            if(data?.type?.target_id === 'microsite'){
              // remove metatags
                const metaTags = document.querySelectorAll('meta');
                metaTags.forEach(metaTag => {
                metaTag.remove();
                });
              
                // remove Links
                const linkTags = document.querySelectorAll('link');
                linkTags.forEach(linkTag => {
                  linkTag.remove();
                });
                // remove scripts
                const scriptTag = document.querySelector('script[id="www-widgetapi-script"]');
                if(scriptTag){
                  scriptTag.remove();
                }
                
                const scriptText = document.querySelector('script[type="text/javascript"]');
                if(scriptText){
                  scriptText.remove();
                }
                
                const scriptTags = document.querySelectorAll('script');
                
                scriptTags.forEach(scriptTags => {
                  scriptTags.remove();
                });
                // remove style
                const styleTags = document.querySelectorAll('style');
                styleTags.forEach(styleTags => {
                  styleTags.remove();
                });
            }
            setloader(false);
        } catch (error) {
            console.log(error);
        }
    };
    fetchApiData(API);
  }, []);


  return (
    <>
        <HelmetProvider>
            <Helmet>
                {/* <meta name="robots" content="index,follow" /> */}
            </Helmet>
        </HelmetProvider>
        {loader ? (
        <Loader />
        ) : (
        <>
            <div dangerouslySetInnerHTML={{
                __html: bData?.body?.[0]?.value,
            }}/>
        </>
        )}
    </>
  );
}

export default Microsite;
